import styled from 'styled-components';

import { font, colors, breakpoints } from 'src/modules/styles';

const Text = styled.span`
  word-wrap: break-word;
  ${font.size.s}
  color: ${colors.black};

  ${breakpoints.s`
    word-wrap: normal;
    ${font.size.m}
  `}
`;

export default Text;
