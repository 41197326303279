import styled from 'styled-components';

import { font, colors, margin, breakpoints } from 'src/modules/styles';

const Paragraph = styled.p`
  margin: 0;
  word-wrap: break-word;
  ${margin.bottom.m}
  ${font.size.s}
  color: ${colors.black};

  ${breakpoints.s`
    word-wrap: normal;
    ${font.size.m}
  `}
`;

export default Paragraph;
