import styled from 'styled-components';

import { font, colors, margin, breakpoints } from 'src/modules/styles';

const Subtitle = styled.h2`
  margin: 0;
  ${margin.bottom.m}
  ${font.size.s}
  color: ${colors.black};
  text-transform: uppercase;
    word-wrap: break-word;

  ${breakpoints.s`
    ${font.size.l}
    word-wrap: normal;
  `} 
`;

export default Subtitle;
