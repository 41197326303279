import styled from 'styled-components';

import { font, colors, margin, breakpoints } from 'src/modules/styles';

const Title = styled.h1`
  margin: 0;
  ${margin.bottom.l}
  ${font.size.m}
  color: ${colors.black};
  text-transform: uppercase;
    word-wrap: break-word;

  ${breakpoints.s`
    ${font.size.xl}
    word-wrap: normal;
  `} 
`;

export default Title;
