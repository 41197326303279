import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { Content, HTMLContent, PageContainer } from 'src/modules/layout';
import { Title } from 'src/modules/text';
import { SEO } from 'src/modules/seo';

const S = {
  PageContainer: styled(PageContainer)`
    justify-content: flex-start;
  `,
};

export const AboutPageTemplate = ({
  title,
  metaTitle,
  metaDescription,
  content,
  contentComponent,
  transitionStatus,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <S.PageContainer transitionStatus={transitionStatus}>
      <SEO title={metaTitle} description={metaDescription} />
      <Title>{title}</Title>
      <PageContent className="content" content={content} />
    </S.PageContainer>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  metaTitle: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  transitionStatus: PropTypes.string.isRequired,
};

AboutPageTemplate.defaultProps = {
  content: null,
  contentComponent: null,
};

const AboutPage = ({ data, transitionStatus }) => {
  const { markdownRemark: post } = data;
  const { title, metaTitle, metaDescription } = post.frontmatter;

  return (
    <AboutPageTemplate
      transitionStatus={transitionStatus}
      contentComponent={HTMLContent}
      title={title}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      content={post.html}
    />
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  transitionStatus: PropTypes.string.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        metaTitle
        metaDescription
        title
      }
    }
  }
`;
